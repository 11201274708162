<template>
    <dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="tracks"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.title="{ item }">
                <router-link :to="{name: 'artist.tracks.show', params: {id: item.id}}">
                    {{ item.title }}
                </router-link>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn color="red" @click="deleteTrack(item)">delete</v-btn>
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import Track from '@/models/Track';
import DashboardLayout from "@/layouts/DashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "users-index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            tracks: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Title', value: 'title'},
                {text: 'Artist', value: 'artist.name'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        async deleteTrack(track) {
            let status
            let message

            try {
                status = (await track.delete())['data']['success']
                message = 'Track deleted.'
            } catch (e) {
                status = false
                message = e.response?.data?.message ?? 'Something went wrong. Please try again later.'
            }

            if (status) {
                this.tracks = this.$_.without(this.tracks, track)
            }

            this.showSnackBar({
                color: status ? 'success' : 'error',
                timeout: 3000,
                text: message
            })
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        this.loading = true
        this.tracks = await Track.include('artist').get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
